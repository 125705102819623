









































































































































import i18n, { changeLang } from '@/i18n'
import { Report, IReport } from '@/shared/model/report.model'
import axios from 'axios'
import Vue from 'vue'

export default Vue.extend({
  name: 'ReportTable',

  data () {
    return {
      report: undefined as IReport | undefined,
    }
  },
  computed: {},
  watch: {
    $route: function () {
      this.getReportData()
    },
  },
  created () {
    this.getReportData()
  },

  methods: {
    async getReportData () {
      const id = this.$route.params.rid

      if (id) {
        await axios.get(`/api/report/${id}`).then(result => {
          if (result.data) {
            this.report = result.data
            if (this.report && this.report.language) {
              i18n.locale = this.report.language
              changeLang(this.report.language)
            }
          }
        })
      } else {
        this.report = undefined
      }
    },
    reportIsValid (report: IReport) {
      let valid = true
      if (report.questions) {
        for (const q of report.questions) {
          if (!q.valid) {
            valid = false
          }
        }
      }

      return valid
    },
  },
})
